<template>
  <b-card>

    <!-- form -->
    <b-form class="mt-2">
      <!-- media -->
      <b-media no-body>
        <b-media>
          <template #aside>
            <b-avatar
              ref="previewEl"
              :src.sync="generalData.image_path"
              :text="avatarText(generalData.full_name)"
              size="90px"
              rounded
            />
          </template>
          <!--/ avatar -->
        </b-media>

        <b-media-body class="mt-75 ml-75">
          <!-- upload button -->
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            size="sm"
            class="mb-75 mr-75"
            @click="$refs.refInputEl.$el.click()"
          >
            Upload
          </b-button>
          <b-form-file
            ref="refInputEl"
            accept=".jpg, .png, .gif"
            :hidden="true"
            plain
            @input="inputImageRenderer"
          />
          <!--/ upload button -->

          <!-- reset -->
          <b-button  v-if="generalData.img_file"
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            size="sm"
            class="mb-75 mr-75"
          >
            <span @click="clearImage" class="d-none d-sm-inline">Clear</span>
          </b-button>
          <!--/ reset -->
          <b-card-text>Allowed JPG, GIF or PNG<br/>Image Dimention - 300x300</b-card-text>
        </b-media-body>
      </b-media>
      <!--/ media -->
      <b-row class="mt-2">
        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-1 mr-1"
            @click="updateAvatar"
          >
            Save
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BFormFile, BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BAlert, BCard, BCardText, BMedia, BMediaAside, BMediaBody, BLink, BImg, BAvatar
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import store from '@/store'

export default {
  components: {
    BButton,
    BForm,
    BImg,
    BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BAvatar,
  },
  directives: {
    Ripple,
  },
  props: {
    generalData: {
      type: Object,
      default: () => {},
    },
  },
  
  methods: {
    clearImage() {
      this.$emit('refetch-data')
      this.generalData.img_file = null
    },

    updateAvatar() {
      store.dispatch('app-profile/updateAvatar', this.generalData)
        .then((response) => {
          if(response.data.status == 1){
            this.$swal({
                  icon: 'success',
                  title: response.data.message ? response.data.message : 'successfully updated!',
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                  timeout: 2000
                })
                this.$emit('refetch-data')
          }
          else if(response.data.status == 0){
            if (response.data.message) {
              this.$swal({
                icon: 'error',
                title: response.data.message,
                customClass: {
                  confirmButton: 'btn btn-danger',
                },
              })
            
            }else{
              var errors = response.data.errors;
              this.$swal({
                  icon: 'error',
                  text: errors.join(', ' ),
                  customClass: {
                    confirmButton: 'btn btn-danger',
                  },
                })
            }
          }
        })
    },
  },
  setup(props) {
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => {
      // eslint-disable-next-line no-param-reassign
      props.generalData.img_file = base64
      props.generalData.image_path = base64
    })
    return {
      avatarText,
      refInputEl,
      previewEl,
      inputImageRenderer,
    }
  },
}
</script>

<template>
  <b-card>
    <b-form>
      <b-row>
        <b-col cols="12">
          <div class="d-flex align-items-center mb-2">
            <feather-icon
              icon="LinkIcon"
              size="18"
            />
            <h4 class="mb-0 ml-75">
              Others Info
            </h4>
          </div>
        </b-col>

        <!-- Google -->
        <b-col md="6">
          <b-form-group
            label-for="account-google"
            label="Google ID"
          >
            <b-form-input
              id="account-google"
              v-model="localOptions.google_id"
              placeholder="Add Google ID"
            />
          </b-form-group>
        </b-col>
        <!--/ Google -->

        <!-- Slack -->
        <b-col md="6">
          <b-form-group
            label-for="account-Slack"
            label="Slack User ID"
          >
            <b-form-input
              id="account-Slack"
              v-model="localOptions.slack_user_id"
              placeholder="Add Slack ID"
            />
          </b-form-group>
        </b-col>
        <!--/ Slack -->

        <!-- Zoho -->
        <b-col md="6">
          <b-form-group
            label-for="zoho-client-id"
            label="Zoho Client ID"
          >
            <b-form-input
              id="zoho-client-id"
              v-model="localOptions.zoho_client_id"
              placeholder="Add Zoho ID"
            />
          </b-form-group>
        </b-col>

        <b-col md="6">
          <b-form-group
            label-for="zoho-client-secret"
            label="Zoho Client Secret"
          >
            <b-form-input
              id="zoho-client-secret"
              v-model="localOptions.zoho_client_secret"
              placeholder="Add Zoho Secret"
            />
          </b-form-group>
        </b-col>

        <b-col md="6">
          <b-form-group
            label-for="zoho-organization-id"
            label="Zoho Organization ID"
          >
            <b-form-input
              id="zoho-organization-id"
              v-model="localOptions.zoho_organization_id"
              placeholder="Add Zoho Organization ID"
            />
          </b-form-group>
        </b-col>

        <b-col md="6">
          <b-form-group
            label-for="zoho-location"
            label="Zoho Location"
          >
            <b-form-input
              id="zoho-location"
              v-model="localOptions.zoho_location"
              placeholder="Add Zoho Location"
            />
          </b-form-group>
        </b-col>
        <!--/ Zoho -->

        <!-- stripe -->
        <b-col md="6">
          <b-form-group
            label="Stripe API Key"
            label-for="stripe-api-key"
          >
            <b-form-input
              id="stripe-api-key"
              v-model="localOptions.stripe_api_key"
              placeholder="Add Stripe API Key"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Stripe Publishable Key"
            label-for="stripe-publishable-key"
          >
            <b-form-input
              id="stripe-publishable-key"
              v-model="localOptions.stripe_publishable_key"
              placeholder="Add Stripe Publishable Key"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Stripe Test API Key"
            label-for="stripe-test-api-key"
          >
            <b-form-input
              id="stripe-test-api-key"
              v-model="localOptions.stripe_test_api_key"
              placeholder="Add Stripe Test API Key"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Stripe Test Publishable Key"
            label-for="stripe-test-publishable-api-key"
          >
            <b-form-input
              id="stripe-test-publishable-api-key"
              v-model="localOptions.stripe_test_publishable_key"
              placeholder="Add Stripe Test Publishable Key"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Stripe Mode"
            label-for="stripe-mode"
          >
            <b-form-input
              id="stripe-mode"
              v-model="localOptions.stripe_mode"
              placeholder="Add Stripe Mode"
            />
          </b-form-group>
        </b-col>
        <!--/ stripe -->

        <!-- asana token -->
        <b-col md="6">
          <b-form-group
            label-for="asana-token"
            label="Asana Token"
          >
            <b-form-input
              id="asana-token"
              v-model="localOptions.asana_token"
              placeholder="Add Asana Token"
            />
          </b-form-group>
        </b-col>
        <!--/ asana token -->

        <!-- buttons -->
        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-1 mr-1"
            @click="updateOtherInfo"
          >
            Save changes
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="reset"
            class="mt-1 ml-25"
            variant="outline-secondary"
            @click.prevent="resetForm"
          >
            Reset
          </b-button>
        </b-col>
        <!--/ buttons -->
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BCardText, BLink, BAvatar,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BCardText,
    BLink,
    BAvatar,
  },
  directives: {
    Ripple,
  },
  props: {
    othersData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      localOptions: JSON.parse(JSON.stringify(this.othersData)),
    }
  },
  methods: {
    resetForm() {
      this.localOptions = JSON.parse(JSON.stringify(this.othersData))
    },

    updateOtherInfo() {
      store.dispatch('app-profile/updateOtherInfo', this.localOptions)
        .then((response) => {
          if(response.data.status == 1){
            this.$swal({
                  icon: 'success',
                  title: response.data.message ? response.data.message : 'successfully updated!',
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                  timeout: 2000
                })
                this.$emit('refetch-data')
          }
          else if(response.data.status == 0){
            if (response.data.message) {
              this.$swal({
                icon: 'error',
                title: response.data.message,
                customClass: {
                  confirmButton: 'btn btn-danger',
                },
              })
            
            }else{
              var errors = response.data.errors;
              this.$swal({
                  icon: 'error',
                  text: errors.join(', ' ),
                  customClass: {
                    confirmButton: 'btn btn-danger',
                  },
                })
            }
          }
        })
    },
  },
}
</script>

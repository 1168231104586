<template>
  <b-tabs
    vertical
    content-class="col-12 col-md-9 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-3 col-12"
    nav-class="nav-left"
  >

    <!-- general tab -->
    <b-tab active>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="UserIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Account</span>
      </template>

      <account-setting-general
        v-if="userData"
        :general-data="userData"
        @refetch-data="refetchData"
      />
    </b-tab>
    <!--/ general tab -->

    <!-- info -->
    <b-tab>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="InfoIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">General Info</span>
      </template>

      <account-setting-information
        v-if="userData"
        :information-data="userData"
        @refetch-data="refetchData"
      />
    </b-tab>

    <!-- Others info -->
    <b-tab>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="LinkIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Others Info</span>
      </template>

      <account-setting-others
        v-if="userData"
        :others-data="userData"
        @refetch-data="refetchData"
      />
    </b-tab>

    <!-- Profile picture -->
    <b-tab>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="ImageIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Profile Image</span>
      </template>

      <account-setting-image
        v-if="userData"
        :general-data="userData"
        @refetch-data="refetchData"
      />
    </b-tab>

    <!-- change password tab -->
    <b-tab>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="LockIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Change Password</span>
      </template>

      <account-setting-password />
    </b-tab>
    <!--/ change password tab -->

  </b-tabs>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
import AccountSettingGeneral from './AccountSettingGeneral.vue'
import AccountSettingPassword from './AccountSettingPassword.vue'
import AccountSettingInformation from './AccountSettingInformation.vue'
import AccountSettingOthers from './AccountSettingOthers.vue'
import AccountSettingNotification from './AccountSettingNotification.vue'
import AccountSettingImage from './AccountSettingImage.vue'
import { ref, onUnmounted, onMounted } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import profileStoreModule from './profileStoreModule'

export default {
  components: {
    BTabs,
    BTab,
    AccountSettingGeneral,
    AccountSettingPassword,
    AccountSettingInformation,
    AccountSettingOthers,
    AccountSettingNotification,
    AccountSettingImage,
  },
  data() {
    return {
      options: {},
    }
  },
  beforeCreate() {
    this.$http.get('/account-setting/data').then(res => { this.options = res.data })
  },
  setup() {
    const userData = ref(null)

    const PROFILE_APP_STORE_MODULE_NAME = 'app-profile'

    // Register module
    if (!store.hasModule(PROFILE_APP_STORE_MODULE_NAME)) store.registerModule(PROFILE_APP_STORE_MODULE_NAME, profileStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PROFILE_APP_STORE_MODULE_NAME)) store.unregisterModule(PROFILE_APP_STORE_MODULE_NAME)
    })

    const getUserData = async () => {
      store.dispatch('app-profile/fetchProfile')
        .then((response) => { 
            userData.value = response.data.data
        })
        .catch(error => {
          if (error.response.status === 404) {
            userData.value = undefined
          }
        })
    }

    onMounted(getUserData)

    const refetchData = async () => {getUserData()}

    return {
      userData,
      refetchData,
    }
  },
}
</script>

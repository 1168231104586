import axios from '@axios'
axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken')
import {checkResponseStatus} from '../../checkResponseStatus'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchProfile(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/my-profile')
          .then((response) =>  {
              checkResponseStatus(response.data)
              resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    updateAccount(ctx, requestData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/v1/my-profile/update-account', requestData)
          .then((response) =>  {
              checkResponseStatus(response.data)
              resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    updateOtherInfo(ctx, requestData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/v1/my-profile/update-other-info', requestData)
          .then((response) =>  {
              checkResponseStatus(response.data)
              resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    updateInfo(ctx, requestData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/v1/my-profile/update-info', requestData)
          .then((response) =>  {
              checkResponseStatus(response.data)
              resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    updateAvatar(ctx, requestData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/v1/my-profile/update-avatar', requestData)
          .then((response) =>  {
              checkResponseStatus(response.data)
              resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    changePassword(ctx, requestData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/v1/my-profile/change-password', requestData)
          .then((response) =>  {
              checkResponseStatus(response.data)
              resolve(response)
          })
          .catch(error => reject(error))
      })
    },

  }
}
